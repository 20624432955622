import React from 'react'
import Layout from "../components/layout"
import { Link } from "gatsby"
import Footer from '../components/footer'
import BgImage from '../images/bg/solutions-page-banner.jpg'
import SocialIconsFooter from '../components/social-icons-footer'
import { StaticImage } from 'gatsby-plugin-image'
import s1 from '../images/pragicts-software-development-process.png'
const WebsiteDeliveryScope = () => {
    return (
        <Layout pageTitle="Consideration for WordPress" og_desc="">
            <div id="wrapper" className="single-page-wrap">
                <div className="content">
                    <div className="single-page-decor"></div>
                    <div className="single-page-fixed-row">
                        <div className="scroll-down-wrap">
                            <div className="mousey">
                                <div className="scroller"></div>
                            </div>
                            <span>Scroll Down</span>
                        </div>
                        <Link to="/" className="single-page-fixed-row-link"><i className="fal fa-arrow-left"></i> <span>Back to home</span></Link>
                    </div>

                    <section className="parallax-section dark-bg sec-half parallax-sec-half-right" data-scrollax-parent="true">
                        <div className="bg par-elem"  data-bg={BgImage} data-scrollax="properties: { translateY: '30%' }"></div>
                        <div className="overlay"></div>
                        <div className="pattern-bg"></div>
                        <div className="container">
                            <div className="section-title">
                                <h2>CONSIDERATION FOR <br/>
<span> WORDPRESS</span></h2>
                                <p></p>
                            
                            </div>
                            <a href="#sec1" className="custom-scroll-link hero-start-link"><span>Let's Start</span> <i className="fal fa-long-arrow-down"></i></a>
                        </div>
                    </section>
                    <section data-scrollax-parent="true" id="sec1">
                        <div className="section-subtitle left-pos"  data-scrollax="properties: { translateY: '-250px' }" ><span>//</span>WORDPRESS</div>
                        <div className="container main-about">
                          

<p style={{fontSize: '13px' }}>Since its humble beginnings in 2003 as a blogging platform, WordPress has evolved into the most dominant CMS in the market. With a staggering 43.2% of websites powered by WordPress as of March 2024, it’s clear that WordPress has not only established itself as a leader but also excelled in the industry.</p><br/>
                            
                            <h2>ARGUMENTS FOR WORDPRESS</h2>
                            
                           <p>< h3 style={{fontSize: '15px',fontWeight:'bold',color:'black'}}>Straight Forward</h3> <h6 style={{fontSize: '13px'}}>- A no-code solution. Does not necessarily require technical/programming expertise to build a website on WordPress. Therefore it comes at a lower price to bespoke solutions.</h6>
                          </p>
                          <p>< h3 style={{fontSize: '15px',fontWeight:'bold',color:'black'}}>User Friendly CMS</h3> <h6 style={{fontSize: '13px'}}>- One of the key strengths of WordPress is its user-friendly interface, a legacy of its blogging origins. Navigating the backend, editing pages, and uploading new content are straightforward tasks for admins and editors. Even without extensive website management knowledge, WordPress empowers you to organize your content easily.</h6>
                          </p>
                          <p>< h3 style={{fontSize: '15px',fontWeight:'bold',color:'black'}}>Plugins</h3> <h6 style={{fontSize: '13px'}}>- There are nearly 60,000 mostly free and paid plugins available to WordPress users. These need to be checked for compatibility with the template for implementation.</h6>
                          </p>
                          <p>< h3 style={{fontSize: '15px',fontWeight:'bold',color:'black'}}>SEO</h3> <h6 style={{fontSize: '13px'}}>- With over one billion websites on the internet, SEO (search engine optimization) is more vital than ever before, and there are some very robust SEO plugins available to help optimize content, target keywords, add page titles and meta descriptions, and more—our favorite WordPress plugin in Yoast SEO.</h6>
                          </p>
                          
                          <h2>ARGUMENTS AGAINST WORDPRESS</h2>
                          <p>< h3 style={{fontSize: '15px',fontWeight:'bold',color:'black'}}>Updates (Less than Half of all WordPress Installations are Updated to the Latest Version)</h3> <h6 style={{fontSize: '13px'}}>- Installing updates is vital to the health of your WordPress site but can cause problems if not done thoughtfully. Some WordPress themes are updated and improved often. These updates are essential to maintain the highest level of security on WordPress websites. Sometimes, updates create issues for a website, so you may have to revert to an older WordPress version.</h6>
                          </p>
                          <p>< h3 style={{fontSize: '15px',fontWeight:'bold',color:'black'}}>Vulnerabilities (96.2% of all Infected CMS Websites are WordPress)</h3> <h6 style={{fontSize: '13px'}}>- One of the more well-known drawbacks to using WordPress is the potential security vulnerabilities this CMS can pose. Outdated core and plugins, lack of unique passwords, and no security hardening are all site elements that make WordPress a popular window of opportunity for hackers with malicious intent.Since the templates are written by their parties, one cannot certainly assure whether there are vulnerabilities in the source code. There are well-articulated situations of such attacks</h6>
                          </p>
                          <p>< h3 style={{fontSize: '15px',fontWeight:'bold',color:'black'}}>WordPress Paints a Bullseye on Your Website</h3> <h6 style={{fontSize: '13px'}}>- It's really easy to determine if your website is built with WordPress. In fact, most of the time it's announced in the footer of your site. This is why WordPress sites get over 132 million spam messages every month. The number of spam comments on a WordPress site is 24 times higher than the number of legitimate comments. If it's that easy for spammers to know your site is WordPress, it's just that easy for malicious bots to find out as well. As it turns out, the majority of WordPress websites are hacked by bots. So you're not being targeted because of something you said, or because of your great business success. Your WordPress site is being targeted by hackers because it is WordPress.</h6>
                          </p>
                          <p>< h3 style={{fontSize: '15px',fontWeight:'bold',color:'black'}}>Extensibility</h3> <h6 style={{fontSize: '13px'}}>- Extensibility beyond the theme requires depth of template and WordPress expertise, which most companies fall short on.</h6>
                          </p>
                          <p>< h3 style={{fontSize: '15px',fontWeight:'bold',color:'black'}}>Speed</h3> <h6 style={{fontSize: '13px'}}>- Some themes contain a lot of unnecessary generic code. This can decrease the speed of the site and cause slow load times.</h6>
                          </p>
<div>










</div> 





















                        </div>
                     
                        <div className="bg-parallax-module" data-position-top="50"  data-position-left="20" data-scrollax="properties: { translateY: '-250px' }"></div>
                        <div className="bg-parallax-module" data-position-top="40"  data-position-left="70" data-scrollax="properties: { translateY: '150px' }"></div>
                        <div className="bg-parallax-module" data-position-top="80"  data-position-left="80" data-scrollax="properties: { translateY: '350px' }"></div>
                        <div className="bg-parallax-module" data-position-top="95"  data-position-left="40" data-scrollax="properties: { translateY: '-550px' }"></div>
                        <div className="sec-lines"></div>
                    </section>
                        
                    <SocialIconsFooter />
                </div>
                <Footer />
            </div>
        </Layout>
    )
}

export default WebsiteDeliveryScope
